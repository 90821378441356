
import url from 'postcss-url'
import service from './ajax'
// 充值排名
export function ranking (data) {
    return service({
      method: 'post',
      url: '/generate_chart_topup_analysis',
      data
    })
  }
//查看查看会员规则

export function vipRule(data) {
    return service({
        url: '/query_member_rule',
        method: 'post',
        data
    })
}

//新增会员规则
export function insertRule(data) {
  return service({
      url: '/insert_member_rule',
      method: 'post',
      data
  })
}

//会员明细账
export function vipDetail(data) {
    return service({
        url: '/query_mc_trans_list_detail',
        method: 'post',
        data
    })
}
//根据充值日期-计算期初余额
export function get_amt_bgn_of_date(data){
  return service({
    url:'/get_amt_bgn_of_date',
    method:'post',
    data
  })
}
//新增会员充值单
export function insert_member_topup(data){
  return service({
    url:'/insert_member_topup',
    method:'post',
    data
  })
}
//会员明细账
export function query_mc_trans_list_detail(data){
  return service({
    url:'/query_mc_trans_list_detail',
    method:'post',
    data
  })
}
//会员总账
export function query_mc_trans_list_gather(data){
  return service({
    url:'/query_mc_trans_list_gather',
    method:'post',
    data
  })
}
//会员流水
export function query_mc_trans_gather_data(data){
  return service({
    url:'/query_mc_trans_gather_data',
    method:'post',
    data
  })
}
///根据充值金额-计算赠送金额
export function get_amt_give_of_amt_topup(data){
  return service({
    url:'/get_amt_give_of_amt_topup',
    method:'post',
    data
  })
}
//会员卡余额
export function get_amt_end_of_cust(data){
  return service({
    url:'/get_amt_end_of_cust',
    method:'post',
    data
  })
}
//组织结构树，既有部门，又有人员 tree,不带目标
export function query_dept_staff_tree_aim_not(data){
  return service({
    url:'/query_dept_staff_tree_aim_not',
    method:'post',
    data
  })
}
//会员卡账明细导出
export function export_detail_mc_acct(data){
  return service({
    url:'/export_detail_mc_acct',
    method:'post',
    data
  })
}
//会员卡账汇总导出
export function export_gather_mc_acct(data){
  return service({
    url:'/export_gather_mc_acct',
    method:'post',
    data
  })
}
//获取客户手机号
export function query_mobile_list_of_customer(data){
  return service({
    url:'/query_mobile_list_of_customer',
    method:'post',
    data
  })
}

export function getRank(data) {
  return service({
    url: '/generate_chart_topup_rank',
    method: 'post',
    data
  })
}

export function insertForm(data) {
  return service({
    url: '/insert_flow_info',
    method: 'post',
    data
  })
}
/**
 * 会员充值单  查询客户的累计充值金额
 * @param {ent_id,cust_id,amt_topup} data
 */
export function query_cust_amt_topup_sum (data){
  return service({
    url:'/query_cust_amt_topup_sum',
    method:'post',
    data
  })
}

/**
 * 查询列表：支付方式
 * @param {ent_id,form_type} data
 * 1:会员充值单 2.回款单
 */
export function query_pay_type_list (data){
  return service({
    url:'/query_pay_type_list',
    method:'post',
    data
  })
}
/**
 *  会员充值单 -- 查询客户的会员等级，会员折扣
 * @param { ent_id,cust_id,amt_topup} data
 */
export function query_cust_mc_level_discount_of_topup (data){
  return service({
    url:'/query_cust_mc_level_discount_of_topup',
    method:'post',
    data
  })
}
/**
 * 回款单  -- 查询客户的会员等级，会员折扣
 * @param { ent_id,cust_id} data
 */
export function query_cust_mc_level_discount (data){
  return service({
    url:'/query_cust_mc_level_discount',
    method:'post',
    data
  })
}
/**
 *回款单--根据折扣判断是否可以提交
 * @param {ent_id} data
 */
export function query_mc_pay_rate (data){
  return service({
    url:'/query_mc_pay_rate',
    method:'post',
    data
  })
}

export function getDeptList (data) {
  return service({
    url: '/query_ent_dept_list_aim_tile',
    method: 'post',
    data
  })
}